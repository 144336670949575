interface Window {
    webkit?: any;
}

declare var window: Window;

export function sendUserToAppHomeScreen() {
  try {
    window.webkit.messageHandlers.exit.postMessage({})
  } catch (err) {
    console.log('error on calling app to go back to home')
  }
}