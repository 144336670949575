import React from 'react';
import { DesktopHeader, DesktopHeaderMenu, DesktopScreenTitle, A8Button } from 'av8-ui';
import { textContent } from '../../../utils';
import {
  PageContentContainer,
  BasePageContainer,
} from './containers';
import { useRequestContext } from '../../../context/RequestContext';
import { useAuthContext } from '../../../context/AuthContext';
import { redirectTo } from '../../../utils/desktopRedirector';

const PageContent = ({ children }: { children?: JSX.Element[] }) => {
  return <PageContentContainer children={children} />;
};

export const BasePage = ({ children }: { children?: JSX.Element[] }) => {
  const { isEmbedded } = useRequestContext();
  const { authSBL } = useAuthContext();

  const handleLogout = () => authSBL.signOut();
  const handleMenuClick = () => redirectTo('account');
  const handleExitClick = () => redirectTo('account');

  const rightMenu = (
    <DesktopHeaderMenu>
      <A8Button variant="text-white" onClick={handleMenuClick} href="#item1">Menu</A8Button>
      <A8Button variant="text-white" onClick={handleLogout} href="#item2">Logout</A8Button>
    </DesktopHeaderMenu>
  );

  const screenTitleProps = {
    title: textContent.billing.title,
    leftAction: (<A8Button onClick={handleExitClick} variant="text-white">Exit</A8Button>),
  };

  const Header = () => (
    <div>
      <DesktopHeader rightMenu={rightMenu} />
      <DesktopScreenTitle {...screenTitleProps} />
    </div>
  );

  return (
    <BasePageContainer>
      {isEmbedded ? null : <Header />}
      <PageContent children={children} />
    </BasePageContainer>
  );
};
