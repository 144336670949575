import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { useRequestContext } from '../../context/RequestContext';
import { redirectTo } from '../../utils/desktopRedirector';
import { ROUTE_PATHS } from './routes';

type RouteType = Partial<{
    isProtected: boolean,
    children: JSX.Element,
}>;

const AuthRoute = ({
    isProtected = true,
    children,
    ...rest
}: RouteType) => {
    const { isAuthed } = useAuthContext();
    const { isEmbedded } = useRequestContext();
  
    if (!isAuthed && isProtected) {
      if (isEmbedded) {
        return <Redirect to={ROUTE_PATHS.unauthorized} />
      } else {
        redirectTo('login')
      }
    }

  return (<Route {...rest}>{children}</Route>)
}

export default AuthRoute;
